import { AuthService } from '../auth/auth.service';
import { HttpClient } from '@angular/common/http';
import { ISubscriptionResponse } from '@entities';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class SubscriptionService {
  constructor(
    private httpClient: HttpClient,
    private authService: AuthService
  ) {}

  moveSubscription(userID: number, subID: number): Observable<ISubscriptionResponse> {
    return this.httpClient.post<ISubscriptionResponse>(
      `${environment.apiUrl2}/api/subscription`,
      {
        userIdTo: userID,
        subscriptionId: subID,
      },
      {
        headers: {
          'x-token': `ApiKey="${this.authService.getAccessToken()}"`,
        },
      }
    );
  }
}
