import { BehaviorSubject, Observable, tap } from 'rxjs';

import { AuthService } from '../auth/auth.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { IUser, IUserInfo } from '@entities';

@Injectable()
export class UserService {
  private user$ = new BehaviorSubject<IUser | null>(null);

  constructor(
    private httpClient: HttpClient,
    private authService: AuthService
  ) {}

  getUser(): Observable<IUser> {
    return this.httpClient
      .get<IUser>(`${environment.apiUrl}/api/selfuser.json`, {
        headers: {
          'x-token': `ApiKey="${this.authService.getAccessToken()}"`,
        },
      })
      .pipe(tap(user => this.user$.next(user)));
  }

  getCurrentUser$(): Observable<IUser> {
    return this.user$.asObservable() as Observable<IUser>;
  }

  getUserInfoByEmail(email: string): Observable<IUserInfo> {
    return this.httpClient.get<IUserInfo>(`${environment.apiUrl2}/api/user/${email}`, {
      headers: {
        'x-token': `ApiKey="${this.authService.getAccessToken()}"`,
      },
    });
  }
}
